import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ProductCard from './ProductCard';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { Theme } from '@material-ui/core/styles';
import productabbracci from '../assets/img/productabbracci.jpeg';
import productbaiocchi from '../assets/img/productbaiocchi.jpeg';
import productbarillacalabrese from '../assets/img/productbarillacalabrese.jpeg';
import productbarillafarfalline from '../assets/img/productbarillafarfalline.jpeg';
import productbarillaricotta from '../assets/img/productbarillaricotta.jpeg';
import productbatticuori from '../assets/img/productbatticuori.jpeg';
import productcuordimela from '../assets/img/productcuordimela.jpeg';
import productdececcofarfalline from '../assets/img/productdececcofarfalline.png';
import productgalletti from '../assets/img/productgalletti.jpeg';
import productmolisanafarfalerigate from '../assets/img/productmolisanafarfalerigate.jpeg';
import productmolisanafusilli from '../assets/img/productmolisanafusilli.jpeg';
import productmolisanalinguine from '../assets/img/productmolisanalinguine.png';
import productmolisanapaccheri from '../assets/img/productmolisanapaccheri.jpeg';
import productmolisanarigatoni from '../assets/img/productmolisanarigatoni.jpeg';
import productnascondini from '../assets/img/productnascondini.jpeg';
import productspicchidisole from '../assets/img/productspicchidisole.jpeg';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxWidth: 1280,
      marginBottom: 50,
      marginTop: 50,
    },
    productsWrapper: {
      display: 'flex',
      width: '100%',

      flexFlow: 'row wrap',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    showMoreWrapper: {
      // letterSpacing: 5,
      // textTransform: 'uppercase',
      // fontSize: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px 0',
    },
    showMoreButton: {
      minWidth: 150,
    },
    line: {
      display: 'block',
      borderBottom: '1px dashed black',
      height: 1,
      width: '100%',
      '&:first-child': {
        marginRight: 20,
      },
      '&:last-child': {
        marginLeft: 20,
      },
    },
    topProductsText: {
      textAlign: 'center',
      margin: 0,
      padding: '0 10px',
      lineHeight: 1.5,
    },
    buttonRoot: {
      borderRadius: 10,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginTop: 20,
      marginBottom: 50,
      minWidth: 280,
      minHeight: 60,
      letterSpacing: 5,
      fontSize: 18,
      // boxShadow: `3px 3px 20px ${theme.palette.common.black}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

export const TopProducts: React.FC = () => {
  const classes = useStyles();
  const [products] = useState([
    {
      name: 'Mulino Bianco Abbracci',
      description:
        "Mulino Bianco's Abbracci are a delicious shortbread biscuits with whipped cream and cocoa halves.",
      image: productabbracci,
    },
    {
      name: 'Mulino Bianco Baiocchi',
      description:
        "Mulino Bianco's Baiocchi are cookies with a soft hazelnut and chocolate cream filling",
      image: productbaiocchi,
    },
    {
      name: 'Barilla Pesto alla Calabrese',
      description:
        'Red pepper and chili combined with the strong flavor of Grana Padano and Pecorino Romano DOP ,was prepared with care, without the addition of preservatives. Ready to give to Your Pasta The Italian taste of good food.',
      image: productbarillacalabrese,
    },
    {
      name: 'Barilla Farfalline',
      description:
        "Barilla's farfalline is a pasta shape dating back to the 1500s - it originated in the Lombardia and Emilia-Romagna part of Northern Italy. Farfalline, which means butterfly in Italian, are rectangular or oval pieces of pasta that are pinched in the middle",
      image: productbarillafarfalline,
    },
    {
      name: 'Barilla Ricotta',
      description:
        'Barilla’s Ricotta sauce blends creamy ricotta flakes and 100% Italian tomatoes in perfect harmony with Grana Padano and Pecorino Romano Cheese for a beautiful and delicate flavour. Prepared according to tradition, with no added preservatives, you’ll be amazed at how full of flavour this sauce really is.',
      image: productbarillaricotta,
    },
    {
      name: 'Mulino Bianco Batticuori',
      description:
        "Mulino Bianco's Batticuori are gorgeous heart shaped shortbread biscuits made with cocoa. Their airy texture helps them absorb a lot of milk whilst maintaining a smooth consistency.",
      image: productbatticuori,
    },
    {
      name: 'Mulino Bianco Cuor di Mela',
      description:
        "Mulino Bianco's Cuor di Mela are shortbread biscuits with apple jam. Perfect with a good black tea and a hint of lime honey.",
      image: productcuordimela,
    },
    {
      name: 'De Cecco Farfalline',
      description:
        'Farfalline (little butterflies), originally from northern Italy, are rectangular with a scalloped edge, pinched together in the centre. The tiny, whimsical shape means they appeal to children in particular who associate them with the animal kingdom. Thanks to the notched edge and the tiny curved wings.',
      image: productdececcofarfalline,
    },
    {
      name: 'Mulino Bianco Galletti',
      description:
        "Mulino Bianco's Galletti are light shortbread biscuits topped with whole grains of sugar to give a crunchy texture.",
      image: productgalletti,
    },
    {
      name: 'Molisana Farfalle Rigate',
      description:
        'One of the first pastificios in Italy, La Molisana has opened their doors in 1912, with the philosophy to put quality and ingredients first. And their motto still stands today. ',
      image: productmolisanafarfalerigate,
    },
    {
      name: 'Molisana Fusilli',
      description:
        'Fusilli is an excellent and versatile pasta, goes well with red or white sauces also perfect for pasta salads.',
      image: productmolisanafusilli,
    },
    {
      name: 'Molisana Linguine',
      description:
        'Linguine, bavette and trenette. The origins of this pasta shape is Ligurian and it is typically served with pesto or fish sauces.',
      image: productmolisanalinguine,
    },
    {
      name: 'Molisana Paccheri',
      description:
        'Paccheri are a short kind of pasta, are excellent with meat ragus. Very tasty with tomato or tomato and vegetable sauces. This format is also particularly suitable for baked casseroles.',
      image: productmolisanapaccheri,
    },
    {
      name: 'Molisana Rigatoni',
      description:
        'Striped externally with big grooves, the Rigatoni are made to recipes rich in condiments and particularly tasty.',
      image: productmolisanarigatoni,
    },
    {
      name: 'Mulino Bianco Nascondini',
      description:
        "Mulino Bianco's Nascondini are shortbread cookies with chocolate. A delicious pastry ideal for dipping, that hides a delicious chocolate to bite.",
      image: productnascondini,
    },
    {
      name: 'Mulino Bianco Spicchi di Sole',
      description:
        "Mulino Bianco's Spicchi di Sole are shortbread biscuits made with butter and eggs.",
      image: productspicchidisole,
    },
  ]);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.topProductsText}>
        <p>
          Please see below a list of some of our amazing products imported
          directly from Italy. To see all of our products click the button
          below.
        </p>
        <Button
          size='large'
          variant='contained'
          classes={{ root: classes.buttonRoot }}
          startIcon={<RestaurantMenuIcon />}
          data-glf-cuid='b8321f95-e4e0-437b-9422-975d50134bf2'
          data-glf-ruid='7c06e56e-4d7a-46bd-b0c2-eca37e9f5344'
        >
          All products
        </Button>
      </div>
      <div className={classes.productsWrapper}>
        {products.slice(0, 8).map((product) => (
          <ProductCard key={product.name} product={product} />
        ))}
      </div>

      <div className={classes.showMoreWrapper}>
        <span className={classes.line}></span>
        <Button
          variant='outlined'
          className={classes.showMoreButton}
          onClick={handleExpandClick}
        >
          {expanded ? 'Show less' : 'Show more'}
        </Button>
        <span className={classes.line}></span>
      </div>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <div className={classes.productsWrapper}>
          {products.slice(8, 16).map((product) => (
            <ProductCard key={product.name} product={product} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default TopProducts;
