import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import CopyrightIcon from '@material-ui/icons/Copyright';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import logo from '../assets/img/logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      // height: 400,
      background: theme.palette.primary.dark,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      padding: '20px 0',
    },
    socialIconsWrapper: {
      // fontSize: 4/2,
    },
    socialIcon: {
      fontSize: 42,
      color: theme.palette.common.white,
      margin: '0 5px',
    },
    copyright: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.common.white,
      margin: 0,
    },
    logo: {
      height: 50,
      // marginBottom: 20,
    },
    logoText: {
      color: theme.palette.common.white,
      letterSpacing: 5,
      fontWeight: 'lighter',
      marginLeft: 20,
    },
    phone: {
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      // margin: 0,
      margin: 10,
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <img src={logo} alt='sitelogo' className={classes.logo} />
        <h1 className={classes.logoText}>Liosis Express</h1>
      </div>
      <div className={classes.socialIconsWrapper}>
        <a
          href='https://www.facebook.com/liosis/'
          target='_blank'
          rel='noreferrer'
        >
          <FacebookIcon className={classes.socialIcon} />
        </a>
        <a
          href='https://www.instagram.com/liosicafe/'
          target='_blank'
          rel='noreferrer'
        >
          <InstagramIcon className={classes.socialIcon} />
        </a>
        <a
          href='https://twitter.com/SebastianoLiosi'
          target='_blank'
          rel='noreferrer'
        >
          <TwitterIcon className={classes.socialIcon} />
        </a>
      </div>
      <p className={classes.phone}>
        <PhoneIcon style={{ marginRight: 5 }} /> 0191 2733303
      </p>
      <p className={classes.phone}>16 Westfield Park, Wallsend NE28 8LB</p>
      <p className={classes.copyright}>
        <CopyrightIcon style={{ marginRight: 5 }} /> 2021 liosiexpress.co.uk.
      </p>
    </div>
  );
};

export default Footer;
