import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import food from '../assets/img/food.jpg';
import logo from '../assets/img/logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topWrapper: {
      position: 'relative',
      background: `url(${food})`,
      minHeight: 800,
      width: '100vw',
      maxWidth: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      // borderBottom: `20px solid #FFF0CD`,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 50,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 0,
      },
    },
    topHeadingTextWrapper: {
      marginLeft: 50,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column nowrap',
      },
      '& p': {
        fontSize: 30,
        fontWeight: 'lighter',
        margin: '10px 0',
        letterSpacing: 5,
        color: theme.palette.common.black,
        background: theme.palette.common.white,
        padding: '2px 10px',
        width: 'fit-content',
        boxShadow: `3px 3px 20px ${theme.palette.common.black}`,
        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
          maxWidth: '90%',
          letterSpacing: 2,
          textAlign: 'center',
          margin: '10px 0',
        },
      },
    },
    logoText: {
      color: theme.palette.common.white,
      margin: 0,
      marginLeft: 30,
      letterSpacing: 10,
      fontSize: 56,
      textShadow: `2px 2px 2px ${theme.palette.common.black}`,
      fontWeight: 'lighter',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
      },
    },
    topHeaderImage: {
      height: 125,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    buttonRoot: {
      borderRadius: 10,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginTop: 20,
      minWidth: 280,
      minHeight: 60,
      letterSpacing: 5,
      fontSize: 18,
      boxShadow: `3px 3px 20px ${theme.palette.common.black}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

export const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.topWrapper}>
      <div className={classes.logoWrapper}>
        <img src={logo} className={classes.topHeaderImage} />
        <h1 className={classes.logoText}>Liosi Express</h1>
      </div>
      <div className={classes.topHeadingTextWrapper}>
        <p>Authentic Italian food delivered or collect in store.</p>
        <p>See our menu and order now.</p>
        <Button
          size='large'
          variant='contained'
          classes={{ root: classes.buttonRoot }}
          startIcon={<RestaurantMenuIcon />}
          data-glf-cuid='b8321f95-e4e0-437b-9422-975d50134bf2'
          data-glf-ruid='7c06e56e-4d7a-46bd-b0c2-eca37e9f5344'
        >
          View menu
        </Button>
      </div>
    </div>
  );
};

export default Header;
