import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    sectionHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& h1': {
        letterSpacing: 5,
        textTransform: 'uppercase',
        fontSize: 40,
        whiteSpace: 'nowrap',
      },
      '& span': {
        display: 'block',
        borderBottom: '2px solid black',
        height: 2,
        width: '100%',
        '&:first-child': {
          marginRight: 20,
        },
        '&:last-child': {
          marginLeft: 20,
        },
      },
    },
  })
);

interface IOwnProps {
  heading: string;
}

export const SectionHeading: React.FC<IOwnProps> = ({ heading }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionHeading}>
      <span></span>
      <h1>{heading}</h1>
      <span></span>
    </div>
  );
};

export default SectionHeading;
