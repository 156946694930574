import { createStyles, makeStyles } from '@material-ui/core/styles';

import SectionHeading from './SectionHeading';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      // height: 300,
      // background: '#FFF1CD',
      width: '100vw',
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      // borderTop: `10px solid ${theme.palette.primary.main}`,
    },
    headingWrapper: {
      maxWidth: 1280,
      width: '100%',
    },
  })
);

export const Map: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.headingWrapper}>
        <SectionHeading heading='Find Us' />
      </div>
      <iframe
        width='100%'
        height='400'
        style={{ border: 0 }}
        loading='lazy'
        allowFullScreen
        src='https://www.google.com/maps/embed/v1/place?q=place_id:EigxNiBXZXN0ZmllbGQgUGFyaywgV2FsbHNlbmQgTkUyOCA4TEIsIFVLIlASTgo0CjIJFQlskkpwfkgRMDHpPINJTf4aHgsQ7sHuoQEaFAoSCZXqgpJKcH5IESJAvkKrwr2gDBAQKhQKEgnHJOPySnB-SBFXcLUDhL9Ntg&key=AIzaSyAvm60i8V0CalFHpFBZuA5Z4Baa3HraZTs'
      ></iframe>
    </div>
  );
};

export default Map;
