import { createStyles, makeStyles } from '@material-ui/core/styles';

import About from './components/About';
import Footer from './components/Footer';
import Header from './components/Header';
import Map from './components/Map';
import OpeningHours from './components/OpeningHours';
import React from 'react';
import TopProducts from './components/TopProducts';
import bg from './assets/img/food.png';

const useStyles = makeStyles(() =>
  createStyles({
    appWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      // background: theme.palette.grey[100],
      background: `url(${bg})`,
    },
  })
);

interface IOwnProps {
  test?: 'prop';
}

export const App: React.FC<IOwnProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.appWrapper}>
      <Header />
      <About />
      <TopProducts />
      <OpeningHours />
      <Map />
      <Footer />
    </div>
  );
};

export default App;
