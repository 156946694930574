import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import SectionHeading from './SectionHeading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aboutWrapper: {
      marginTop: 50,
      textAlign: 'center',
      '& p': {
        fontSize: 28,
        letterSpacing: 2,
        fontWeight: 'lighter',
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },
    },
    aboutContent: {
      width: '70vw',
      boxSizing: 'border-box',
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 10,
      },
    },
    sectionHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& h1': {
        letterSpacing: 5,
        textTransform: 'uppercase',
        fontSize: 40,
      },
      '& span': {
        display: 'block',
        borderBottom: '2px solid black',
        height: 2,
        width: '100%',
        '&:first-child': {
          marginRight: 20,
        },
        '&:last-child': {
          marginLeft: 20,
        },
      },
    },
  })
);

export const About: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.aboutWrapper}>
      <div className={classes.aboutContent}>
        <SectionHeading heading='Welcome' />
        <p>
          Welcome to Liosi Express where you can shop online for authentic
          Italian food and drink. All of our products are imported from Italy
          and sold here online or in our independant Sicilian cafe bar based on
          the River Tyne behind Scotswood Road. Browse our online products and
          purchase for collection or local delivery. Any questions or queries
          please feel free to contact us on 0191 2733303.
        </p>
      </div>
    </div>
  );
};

export default About;
