import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      // height: 500,
      // width: '100%',
      // display: 'flex',
      // justifyContent: 'center',
      // flexFlow: 'column nowrap',
      // alignItems: 'center',
      marginBottom: 50,
      // background: 'white',
      // width: 'auto',
      padding: 50,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: 10,
        margin: 10,
      },
    },
    openingHoursWrapper: {
      // width: '100%',
      // display: 'flex',
      // flexFlow: 'column nowrap',
      // alignItems: 'center',
      // justifyContent: 'center',
    },
    dayTime: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row nowrap',
      fontSize: 24,
      fontWeight: 'lighter',
      letterSpacing: 3,
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    helpText: {
      marginBottom: 40,
    },
    sectionHeading: {
      margin: 0,
    },
  })
);

interface IDayTimeProps {
  day: string;
  time: string;
}

const DayTime: React.FC<IDayTimeProps> = ({ day, time }) => {
  const classes = useStyles();

  return (
    <div className={classes.dayTime}>
      <span>{day}</span>
      <span>{time}</span>
    </div>
  );
};

export const OpeningHours: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.wrapper} raised>
      <CardContent>
        <h1 className={classes.sectionHeading}>Opening Hours</h1>
        <p className={classes.helpText}>
          Feel free to order online at any time of day, but for collections and
          deliveries these are our opening hours:
        </p>
        <div className={classes.openingHoursWrapper}>
          <DayTime day='Monday' time='8:00am - 8:00pm' />
          <DayTime day='Tuesday' time='8:00am - 8:00pm' />
          <DayTime day='Wednesday' time='8:00am - 8:00pm' />
          <DayTime day='Thursday' time='8:00am - 8:00pm' />
          <DayTime day='Friday' time='8:00am - 8:00pm' />
          <DayTime day='Saturday' time='8:00am - 8:00pm' />
          <DayTime day='Sunday' time='Closed' />
        </div>
      </CardContent>
    </Card>
  );
};

export default OpeningHours;
