import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import React from 'react';
import StorefrontIcon from '@material-ui/icons/Storefront';
import logo from '../assets/img/logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300,
      margin: 10,
    },
    media: {
      height: '200px',
      backgroundSize: 'contain',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    cardActionsRoot: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
    },
    avatar: {},
    shopNowButton: {
      // borderWidth: 2,
      // borderRadius: 10,
      // background: theme.palette.common.black,
      // color: theme.palette.common.white,
    },
  })
);

interface IProduct {
  name: string;
  image: string;
  description: string;
}

interface IOwnProps {
  product: IProduct;
}

export const ProductCard: React.FC<IOwnProps> = ({ product }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label='recipe' className={classes.avatar}>
            <img
              src={logo}
              alt='liosisexpress logo'
              style={{ height: '100%', width: '100%' }}
            />
          </Avatar>
        }
        title={product.name}
        // subheader='September 14, 2016'
      />
      <CardMedia
        className={classes.media}
        image={product.image}
        title={product.name}
      />
      <CardActions classes={{ root: classes.cardActionsRoot }}>
        <Button
          variant='outlined'
          size='medium'
          color='primary'
          disableElevation
          startIcon={<StorefrontIcon />}
          classes={{ root: classes.shopNowButton }}
          data-glf-cuid='b8321f95-e4e0-437b-9422-975d50134bf2'
          data-glf-ruid='7c06e56e-4d7a-46bd-b0c2-eca37e9f5344'
        >
          Shop Now
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
