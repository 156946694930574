import { createMuiTheme, darken, lighten } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: lighten('#698E42', 0.2),
      main: '#698E42',
      dark: darken('#698E42', 0.2),
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#CC0000',
    },
    background: {
      default: '#fcfcfc',
    },
    grey: {
      200: '#F5F6FA',
      300: '#CFD8E6',
      400: '#7F8FA6',
      500: '#33383F',
    },
    success: {
      dark: '#7BAE02',
      main: '#9FD916',
    },
  },
  typography: {
    fontFamily: ['FS Me', 'sans-serif'].join(','),
  },
});

export default theme;
